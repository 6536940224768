import iyinPhoto from "@/public/assets/img/iyin.png"
import odunPhoto from "@/public/assets/img/odun.png"
import uzomaPhoto from "@/public/assets/img/uzoma.png"
import yemiPhoto from "@/public/assets/img/yemi.png"
import SidebarMenuItemsMockV2 from "@/src/__mocks__/api/SidebarMenuItemsMockV2"
import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import { LicenceType } from "@/src/sb/business-layer/types"
import {
  ENERGY_PRICE_TRACKER_SLUG,
  V2_FEATURE_TOGGLES
} from "@/src/sb/constants/configs"
import { pages } from "@/src/sb/constants/copy"
import {
  ARTICLE_V3,
  EXTRA_SIDEBAR_ITEMS,
  EXTRA_SIDEBAR_TOP_ITEMS,
  FeatureFlag,
  SIDEBAR_COUNTRIES_CLASS_NAME,
  SIDEBAR_INDUSTRIES_CLASS_NAME,
  SIDEBAR_MAX_DEPTH,
  USE_V2_TAG_CLASSIFICATION
} from "@/src/sb/constants/featureFlags"

export const SUBSCRIPTIONS_PAGE_FAQs = [
  {
    question: "What is Stears Premium?",
    answer: `
      <p>Stears Premium refers to our digital-only subscription service. All Stears Premium content is created by our in-house analysts and focuses on specific industries, issues, themes and companies. Each weekday morning, subscribers will receive an 8am email from our editors introducing that day’s Stears Premium story and why it is important for you.</p>

      <p>To learn more about Stears Premium, please <a href="https://www.stearsng.com/article/stears-premium-information-worth-paying-for" class="text-accent-600">read this message from our CEO</a>.</p>
      
      <div>
        <p>You can access Stears Premium using</p>
        <ul class="list-disc list-inside ml-4">
          <li>
            Personal subscription for individuals
          </li>
          <li>
            Corporate licence for companies and organisations
          </li>
        </ul>
      </div>
      `
  },
  {
    question: "How can I get a personal Stears subscription?",
    // language=HTML
    answer: `
      <div>
        <p>To get a personal Stears subscription, you need to sign up for any of our premium subscription plans.  You can subscribe to any of these three options: </p>
        <ul class="list-disc list-inside ml-4">
          <li>
            Monthly plan
          </li>
          <li>
            Quarterly plan
          </li>
          <li>
            Annual Plan
          </li>
        </ul>
      </div>
      
      <p>To get your Stears subscription, <a href="${pages.subscriptions}?position=faq" class="text-accent-600">click here</a></p>
      
      <p>If you have any questions or need help, please contact <a href="mailto:support@stears.co" class="text-accent-600">support@stears.co</a></p>
    `
  },
  {
    question: "How can I get a corporate licence?",
    answer: `
      <p>Companies, organisations and schools that want firm-wide access to Stears Premium can purchase a corporate licence.  </p>

      <div>
        <p>Corporate licences provide team-wide access to all employees within an organisation, for 1 year. Our corporate licence gives you: </p>
        <ul class="list-disc list-inside ml-4">
          <li>
            Access to a rich library of data-driven insights
          </li>
          <li>
            Access to proprietary sector data-trackers and indices
          </li>
          <li>
            Invitation to subscriber-only events
          </li>
          <li>
            Quarterly sector-focused briefings & presentations directly from analysts
          </li>
          <li>
            Access to Stears’ proprietary insights database
          </li>
        </ul>
      </div>
      
      <p>To learn more about our corporate licence, please fill this <a href="https://share.hsforms.com/13ObtRbV5RMS-Kut-3vvj4g4uj82" class="text-accent-600">form</a>. Our sales team will get in touch with you.</p>
    `
  },
  {
    question: "What forms of payment do you accept?",
    answer: `
      <p>For personal subscriptions, we accept most international and local debit cards. For your convenience and the best experience, we recommend card payment. </p>
      <p>For corporate licences, we accept bank transfers. </p>
      
      <p>If you are having trouble with payment, please email <a href="mailto:support@stears.co" class="text-accent-600">support@stears.co</a>  for assistance. </p>
    `
  },
  {
    question: "How can I cancel my Stears subscription?",
    answer: `
      <p>You can cancel your subscription at any time on your dashboard. You will not be billed for any subsequent subscriptions.</p>

      <p>Although you may cancel, your access to Stears Premium content will remain active. The cancellation will only take effect at the end of your then-current subscription period.</p>
      
      <p>Please note that the option to cancel a Stears subscription is only available to subscribers that paid with their cards.</p>
      
      <p>If you have any additional questions, please email us at <a href="mailto:support@stears.co" class="text-accent-600">support@stears.co</a>.</p>
    `
  },
  {
    question: "Do you have a mobile app?",
    answer: `
      <p>Yes, Stears has a mobile app, and it is available on both iOS and Android devices. Our mobile app makes it easy for you to search and discover content that is right for you.</p>

      <p>To start enjoying our intuitive mobile app, please download it <a href="https://onelink.to/uu49gu" class="text-accent-600">here</a></p>
    `
  }
]

export const SUBSCRIPTIONS_PAGE_TESTIMONIALS = [
  {
    image: odunPhoto,
    name: "Odun Eweniyi",
    work: "Co-founder & COO PiggyVest",
    text: "“Stears has bridged the gap between high-quality reporting on business, policy and the economy and incredible simplicity in passing information across. No one is doing it like them.”"
  },
  {
    image: yemiPhoto,
    name: "Dr. Yemi Kale",
    work: "Former Statistician-General, Nigerian Bureau of Statistics",
    text: "“Stears is one of my preferred and regular sources of high-quality insight on policy and macroeconomic development.”"
  },
  {
    image: uzomaPhoto,
    name: "Uzoma Dozie",
    work: "CEO Sparkle. Former CEO, Diamond Bank",
    text: "“I love Stears. Relevant, readable, and educational material for leaders who are starting, growing, or managing a business.”"
  },
  {
    image: iyinPhoto,
    name: "Iyin Aboyeji",
    work: "Co-founder at Andela & GP at Future Africa",
    text: "“Stears is Africa’s Bloomberg. Period”"
  }
]

export const SUBSCRIPTIONS_PAGE_SUBSCRIBERS = [
  "unnamed.png",
  "pz-cussons.png",
  "infra-credit.png",
  "sterling.png",
  "nigeria-brewries.png",
  "udpn.png",
  "piggyvest.png",
  "paystack.png",
  "nestle.png",
  "luminate.png",
  "pac-holdings.png",
  "business-france.png",
  "future-africa.png",
  "buycoins.png",
  "mtn.png",
  "heineken.png",
  "africa-practice.png",
  "ihs.png"
]

export const SUBSCRIPTIONS_PAGE_SUBSCRIBERS_DARK_THEME = [
  "european-investment-bank_dark-theme.png",
  "pz-cussons_dark-theme.png",
  "nestle_dark-theme.png",
  "mtn_dark-theme.png",
  "sterling_dark-theme.png",
  "shell_dark-theme.png",
  "heineken_dark-theme.png"
]

export const HIDE_ARTICLE_PREMIUM_UPGRADE_BANNER =
  "hide-article-premium-upgrade-banner" // cleared on session expiry
export const HIDE_INTELLIGENCE_SOLUTIONS_BANNER =
  "hide-intelligence-solutions-banner" // cleared on session expiry

export const ENERGY_PRICE_TRACKER_OBJECT = {
  name: "Energy Price Tracker",
  href: URLFactory.report(ENERGY_PRICE_TRACKER_SLUG, false),
  slug: ENERGY_PRICE_TRACKER_SLUG
}

export const DATA_TRACKER = "data-tracker"
export const MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER = [
  /^\/topics\/.+/i,
  /^\/home\/?$/i
]

export const MATCH_TO_IGNORE_INTELLIGENCE_SOLUTIONS_BANNER_PLATFORM = [
  /^\/search\/\?q=.+/i
]

MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER.push(
  ...(V2_FEATURE_TOGGLES.has(ARTICLE_V3)
    ? []
    : [/^\/article\/.+/i, /^\/premium\/article\/.+/i])
)

export const DATA_PAGE_AUTHORIZED_LICENCE_TIERS = [
  LicenceType.INVESTOR,
  LicenceType.PRO,
  LicenceType.STRATEGY
]

export const COMMON_PROPS_USE_CASE_FEATURES: Array<
  FeatureFlag | [FeatureFlag, any]
> = [
  USE_V2_TAG_CLASSIFICATION,
  [EXTRA_SIDEBAR_TOP_ITEMS, {}],
  [SIDEBAR_COUNTRIES_CLASS_NAME, "Countries"],
  [SIDEBAR_INDUSTRIES_CLASS_NAME, "Sectors"],
  [EXTRA_SIDEBAR_ITEMS, SidebarMenuItemsMockV2()],
  [SIDEBAR_MAX_DEPTH, 2]
]

export const DEALS_SCREENER_PATH_LABEL_HASH_MAP: Record<string, string> = {
  "countryOfOperation.slug": "Countries of Operation",
  "company.ownershipStatus": "Ownership Status",
  "deal.dealSize": "Deal Size ($M)",
  "deal.percentageEquityStake": "Equity Stake (%)",
  "deal.announcementDate": "Announcement Date",
  "deal.investmentRound": "Investment Rounds",
  "investmentType.slug": "Investment Type",
  "financingType.slug": "Financing Type",
  "deal.fund": "Fund",
  "investor.slug": "Buyers/Investors",
  "leadInvestor.slug": "Lead Investor/Buyer",
  "subsector.slug": "Subsector",
  "company.tag.slug": "Subsector",
  "financial.ebitda": `Ebitda ($M)`,
  "financial.revenue": `Revenue ($M)`
}

export const X_TO_X_HASH_MAP: Record<string, string> = {
  "countryOfOperation.slug": "countryOfOperations",
  "company.tag.slug": "company.tag.slug",
  "company.ownershipStatus": "ownerShipStatus",
  "deal.dealSize": "dealSize",
  "deal.percentageEquityStake": "equityStake",
  "deal.announcementDate.predefinedOperation":
    "announcementDate.predefinedOperation",
  "deal.announcementDate.operationName": "announcementDate.operationName",
  "deal.announcementDate.start": "announcementDate.start",
  "deal.announcementDate.end": "announcementDate.end",
  "deal.investmentRound": "investmentRounds",
  "deal.fund": "fund",
  "investmentType.slug": "investmentTypeList",
  "financingType.slug": "financingType",
  "investor.slug": "buyersInvestor",
  "leadInvestor.slug": "leadInvestorBuyer",
  "subsector.slug": "subSector",
  "financial.ebitda": "financial.ebitda",
  "financial.revenue": "financial.revenue"
}

export const KNOWN_QUERY_CRITERIA_KEY = [
  "company.tag.slug[]",
  "countryOfOperation.slug[]",
  "company.ownershipStatus[]",
  "deal.dealSize.min",
  "deal.dealSize.max",
  "deal.percentageEquityStake.min",
  "deal.percentageEquityStake.max",
  "deal.announcementDate.predefinedOperation",
  "deal.announcementDate.operationName",
  "deal.announcementDate.start",
  "deal.announcementDate.end",
  "deal.investmentRound[]",
  "deal.fund[]",
  "financial.ebitda.min",
  "financial.ebitda.max",
  "financial.revenue.min",
  "financial.revenue.max",
  "investmentType.slug[]",
  "financingType.slug[]",
  "investor.slug[]",
  "leadInvestor.slug[]",
  "subsector.slug[]"
]
