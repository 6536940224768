import { growthBookCommonConfig } from "@/src/common/data-layer/datasources/GrowthBook"
import {
  ELECTION_API_REDESIGN_DATE,
  GET_NOW,
  REFRESH_INTERVAL_SECS,
  UPCOMING_ELECTIONS_YEAR
} from "@/src/elections/business-layer/config"
import { Race, UpcomingElection } from "@/src/elections/business-layer/types"
import { Data } from "@/src/elections/data-layer"
import { GrowthBook, setPolyfills } from "@growthbook/growthbook-react"
import _ from "lodash"
import { GetServerSidePropsContext } from "next"

import { ElectionTime, OperatingSystems } from "../types"

//inspired by https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
export const getUserOS = (userAgent: string): OperatingSystems => {
  if (/iphone|ios|ipad/i.test(userAgent)) {
    return "iOS"
  }

  if (/android|samsung/i.test(userAgent)) {
    return "android"
  }

  return "unknown"
}

export function groupBy<T>(
  arr: Array<T>,
  keyFunc: (el: T) => string
): Record<string, Array<T>> {
  return arr.reduce(
    (grouped: Record<string, Array<T>>, element: T) => ({
      ...grouped,
      [keyFunc(element)]: grouped[keyFunc(element)]
        ? grouped[keyFunc(element)].concat(element)
        : [element]
    }),
    {}
  )
}

export function byKey<T>(
  items: T[] | undefined,
  key: keyof T
): Record<string, T> {
  return items && items.length !== 0
    ? items.reduce(
        (prev, current) => ({
          ...prev,
          [current[key] as any]: current
        }),
        {}
      )
    : {}
}

export function skipEmpty<T>(
  obj: Record<string, T | undefined>
): Record<string, T> {
  return Object.entries(obj)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && (value as any) !== ""
    )
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})
}

export const removeUndefined = <T>(obj: T): T => JSON.parse(JSON.stringify(obj))

export const qs = (filter: Record<string, string | undefined>) =>
  Object.keys(filter).length === 0
    ? ""
    : "?" +
      decodeURIComponent(new URLSearchParams(skipEmpty(filter)).toString())
// decode because encodeURI will be used to fetch URL later. Prevent double encoding

export const transformUpdatedPreference = ({
  id: election_id,
  selected: active
}: {
  id: string
  selected: boolean
}): {
  election_id: string
  active: boolean
} => ({
  election_id,
  active
})

export const dedupeList = <T>(list: Array<T>): Array<T> =>
  Array.from(new Set(list).values())

/**
 * @param params Must be a valid query string or object
 */
export const parseSearchParam = (
  params: string | Record<string, any>
): URLSearchParams => {
  if (typeof params === "string") {
    try {
      return new URL(params).searchParams
    } catch (e) {
      const str = params.match(
        /\?([\w-]+(=[\w.\-:%/+]*)?(&[\w-]+(=[\w.\-:%/+]*)?)*)?$/
      )
      return new URLSearchParams(str && str.length ? str[0] : "")
    }
  }

  return new URLSearchParams(params)
}

export const mergeSearchParams = (
  currentParams: string | Record<string, any>,
  withParams?: string | Record<string, any>
): URLSearchParams => {
  // get current search params if any
  const searchParams = parseSearchParam(currentParams)

  if (!withParams) return searchParams

  parseSearchParam(withParams).forEach((value, key) => {
    searchParams.set(key, value)
  })

  return searchParams
}

export const getPercentage = (
  number: number,
  of: number,
  formatResult = false
): string => {
  const ratio = number / of
  const percentage = ((!isNaN(ratio) ? ratio : 0) * 100).toFixed(2)

  if (formatResult) return `${percentage}%`

  return percentage
}

export const formatNumber = (number: number): string =>
  new Intl.NumberFormat().format(number)

/**
 *
 * @param race
 *
 * TODO: Move function to appropriate domain(elections)
 */
export const raceIsRepresentative = (race: Race | string): boolean =>
  ["senate", "house", "state_house"].includes(race)

/**
 *
 * @param electionTime
 * @param race
 * @param stateCode
 *
 * TODO: Move function to appropriate domain(elections)
 */
export const raceHasElectionInTime = (
  electionTime: ElectionTime,
  race: Race,
  stateCode?: string
): boolean =>
  (electionTime?.year === UPCOMING_ELECTIONS_YEAR && race === "president") ||
  Boolean(
    electionTime &&
      electionTime.races[race] &&
      (stateCode ? electionTime.races[race].includes(stateCode) : true)
  )

/**
 *
 * @param electionTimeline
 * @param currentTimeIdx
 * @param race
 * @param stateCode
 * @param lookBackwardFirst
 *
 * TODO: Move function to appropriate domain(elections)
 */
export const findRaceNearestElectionTime = (
  electionTimeline: ElectionTime[],
  currentTimeIdx: number,
  race: Race,
  stateCode?: string,
  lookBackwardFirst = true
): ElectionTime | undefined => {
  const timelineLeftHalf = electionTimeline.slice(0, currentTimeIdx).reverse()
  const timelineRightHalf = electionTimeline.slice(currentTimeIdx + 1)
  let electionTimelineToCheck
  if (lookBackwardFirst) {
    electionTimelineToCheck = timelineLeftHalf.concat(timelineRightHalf)
  } else {
    electionTimelineToCheck = timelineRightHalf.concat(...timelineLeftHalf)
  }

  let cycleCount = 0

  do {
    if (
      raceHasElectionInTime(
        electionTimelineToCheck[cycleCount],
        race,
        stateCode
      )
    ) {
      return electionTimelineToCheck[cycleCount]
    }
    cycleCount++
  } while (cycleCount < electionTimelineToCheck.length)

  return
}

/**
 * should enable live elections result by returning a valid refresh interval zero otherwise
 *
 * @param electionDateTime election year or datetime
 * @param returnValue fallback refresh interval if zero is going to be returned
 *
 * TODO: Consider reimplementing this function
 * TODO: Write test
 * TODO: Move function to appropriate domain(elections)
 */
export const getRefreshInterval = (
  electionDateTime?: string,
  returnValue?: number
) => {
  if (electionDateTime) {
    const electionPeriod = new Date(electionDateTime)
    if (
      electionPeriod >= new Date(ELECTION_API_REDESIGN_DATE) &&
      electionPeriod < GET_NOW()
    ) {
      return REFRESH_INTERVAL_SECS
    }
  }

  return returnValue ?? 0
}

/**
 * Find race info by a specific key
 *
 * TODO: Write test
 * TODO: Move function to appropriate domain(elections)
 */
export const findUpcomingElection = (
  value:
    | string
    | ((
        election: Omit<UpcomingElection, "href">,
        index?: number
      ) => Omit<UpcomingElection, "href"> | undefined),
  by: keyof Omit<UpcomingElection, "href"> = "slug"
) => {
  return (Data.upcomingElections as Omit<UpcomingElection, "href">[]).find(
    typeof value === "function" ? value : (election) => election[by] === value
  )
}

/**
 * Covert all object property to camelCase, return primitive value as is
 *
 * @param data
 */
export const camelCaseObjectProperty = (data: unknown) => {
  if (!_.isObject(data) && !_.isArray(data)) return data

  return _.transform(data, (acc: any, value, key, target) => {
    const camelCaseKey = _.isArray(target) ? key : _.camelCase(key)

    acc[camelCaseKey] = _.isObject(value)
      ? camelCaseObjectProperty(value)
      : value
  })
}

/**
 * Covert all object property to snake_case, return primitive value as is
 *
 * @param data
 */
export const snakeCaseObjectProperty = (data: unknown) => {
  if (!_.isObject(data) && !_.isArray(data)) return data

  return _.transform(data, (acc: any, value, key, target) => {
    const snakeKey = _.isArray(target) ? key : _.snakeCase(key)

    acc[snakeKey] = _.isObject(value) ? snakeCaseObjectProperty(value) : value
  })
}

export const getServerSideGrowthBookInstance = (
  req?: GetServerSidePropsContext["req"]
) => {
  // Set GrowthBook polyfills for server environments
  setPolyfills({
    fetch: globalThis.fetch || require("cross-fetch"),
    EventSource: globalThis.EventSource || require("eventsource"),
    SubtleCrypto: globalThis.crypto?.subtle
  })

  return new GrowthBook({
    ...growthBookCommonConfig,
    attributes: {
      // TODO: get more targeting attributes from request context
      id: (req && req.cookies.DEVICE_ID) ?? null
    }
  })
}
